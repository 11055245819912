const categories = ['Lashes', 'Facials', 'Advanced Treatment', 'Eyebrows', 'Body Treatments'];
const env = {
    env: 'prod',
    service_cat: {
        cat_name: categories,
        flag: ['fullset', 'refill', null], // flags - null is for other categories that don't have flags
        default_indexes_prod: [[9,4], 1, 14], // default indexes for the default services when the service_cat or flag state gets changed
        default_indexes_dev: [[4,1], 9, 18], // same as before for dev environment
        exclude_flag_indexes_dev: [14, 22, 23], // indexes firebase default of lashes services that don't have flags, needs to be excluded in the loop
        exclude_flag_indexes_prod: [0, 1, 6, 10, 11]
    },
    appt_status: {
        1: 'Approved',
        2: 'Break',
        3: 'Pending',
        4: 'Cancelled',
        5: 'No-Show'
    },
    base_api: 'https://us-central1-glow-getter.cloudfunctions.net/clients',
    firebase: {
        apiKey: "AIzaSyBi9z5On5EfqC9zPH1Q4ceJE_Wgus5p61w",
        authDomain: "glow-getter.firebaseapp.com",
        databaseURL: "https://glow-getter.firebaseio.com",
        projectId: "glow-getter",
        storageBucket: "glow-getter.appspot.com",
        messagingSenderId: "576667913663",
        appId: "1:576667913663:web:f4776c25a729cc0b",
    },
    firebaseDev: {
        apiKey: "AIzaSyBTVARWl7wMRvFj7whZjqHDzixQpYLTRIo",
        authDomain: "glow-getter-dev.firebaseapp.com",
        databaseURL: "https://glow-getter-dev.firebaseio.com",
        projectId: "glow-getter-dev",
        storageBucket: "glow-getter-dev.appspot.com",
        messagingSenderId: "703963044030",
        appId: "1:703963044030:web:e759667b9d118997"
    },
    per_page: {
        options: [5, 10],
        default: 10
    },
    accounting: {
        filter_options: ['Today', 'Yesterday', 'This Week', 'Past Week', 'This Month', 'Past Month', 'This Quarter', 'Past Quarter', 'Past 6 Months', 'Past 12 Months', 'This Year', 'Past Year', 'Custom'],
        filter_default: 'This Month',
        expense_types: ['Cash', 'Square', 'CashApp', 'Zelle', 'Wave'],
        types_colors: ['#88bc11', '#000000', '#01bc55', '#dd5500', '#2f60dd'],
        sales_types: ['service', 'product', 'subscription', 'Package'],
        decimalValidator: 'matchRegexp:^\\d+(\\.\\d{1,2})?$',
        report_chart: {
            default_xtitle: 'Days',
            default_ytitle: 'Income',
            default_view: 'day',
            default_data: 'incomes',
            colors: ["#c39493"]
        },
        filter_str: {
            lash_membership: 'rf',
            skin_membership: 'skin',
            membership_income_type: 'subscription'
        }
    },
    chart: {
        height: '250px',
        colors: {
            income: '#bcb498',
            products: '#d8d5ce',
            service: '#bdd8a7',
            tip: '#bbb',
            expense: '#c1efd4'
        }
    },
    calendar: {
        default: 'week',
        default_time: '8:30:00 AM'
    },
    local: {
        income_from_cal: 'income_from_calendar',
        apt_id: 'income_apt_id',
        show_number: 'show_numbers',
        location: 'lc'
    },
    hide_numbers_text: '***',
    dashboard: {
        top_client: 10
    },
    memberships: [
        'Classic 1RF',
        'Classic 2RF',
        'Hybrid 1RF',
        'Hybrid 2RF',
        'Volume 1RF',
        'Volume 2RF',
        'Glow Skin',
        'Brow Bar',
        'Hair Removal'
    ],
    membership: {
        classic_1rf: {
            name: 'Classic 1RF',
            price: 65.99,
            credits: 1
        },
        classic_2rf: {
            name: 'Classic 2RF',
            price: 99.99,
            credits: 2
        },
        hybrid_1rf: {
            name: 'Hybrid 1RF',
            price: 85.99,
            credits: 1
        },
        hybrid_2rf: {
            name: 'Hybrid 2RF',
            price: 140.00,
            credits: 2
        },
        volume_1rf: {
            name: 'Volume 1RF',
            price: 85.99,
            credits: 1
        },
        volume_2rf: {
            name: 'Volume 2RF',
            price: 159.99,
            credits: 2
        },
        glow_skin_econ: {
            name: 'Glow Skin',
            price: 115,
            credits: 1
        },
        brow_bar: {
            name: 'Brow Bar',
            price: 80,
            credits: 1
        },
        hair_removal: {
            name: 'Hair Removal',
            price: 125,
            credits: 1
        }
    },
    imgs: {
        deposit: '',
        member: '',
        loading: ''
    },
    marketing: {
        sms_categories: ['General', ...categories],
        sms_clients_type: ['All', 'Members', 'Non Members', 'Leads', 'Custom'],
        menu: {
            marketing: 'Lead',
            campaigns: 'Campaigns',
            coupons: 'Coupons',
            feedback: 'Feedback',
            giftcards: 'Gift Cards'
        }
    },
    modal_style : {
        top: '50%',
        left: '50%',
        marginLeft: -250,
        marginTop: -250,
        transform: 'translate(-50%, -50)',
        position: 'absolute',
        width: 500,
        outline: 'none',
        backgroundColor: '#fff'
    },
    urls: {
        website: 'https://drglowgetter.com'
    },
    deposit: 50,
    appt: {
        online: 'Booked online'
    },
    cats: ['Lashes', 'Facials', 'Advanced Treatment', 'Eyebrows', 'Body Treatments'],
    permissions: {
        concierge: 'Front Desk'
    },
    employees: [{
        id: 'a',
        title: 'Annette'
    }/*, {
        id: 'b',
        title: 'Keren'
    }, {
        id: 'c',
        title: 'Mckenna'
    }, {
        id: 'd',
        title: 'Maddy'
    }, {
        id: 'e',
        title: 'Melcy'
    }*/,{
        id: 'h',
        title: 'Sarah'
    },{
        id: 'i',
        title: 'Stephanie'
    },{
        id: 'g',
        title: 'Scarlett'
    }],
    all_employees: [
        { id: 'a', name: 'Annette'  },
        { id: 'b', name: 'Keren' },
        { id: 'c', name: 'Mckenna'},
        { id: 'd', name: 'Maddy'  },
        { id: 'e',  name: 'Melcy' },
        { id: 'f', name: 'Susan' },
        { id: 'g', name: 'Scarlett'},
        { id: 'h', name: 'Sarah'},
        { id: 'i', name: 'Stephanie'}],
    default_employee: 'a',
    restrictions: {
        cancellation: 24,
        apt_service_edit: -1, // disable after one hour after the service start
        assigned_to_edit: -1,
        deposit_edit: 0,
        date_time_edit: 1,
        client_edit: -1,
        appt_status_edit: -1
    },
    affiliate: ['mckenna', 'keren', 'sheila'],
    csv: {
        from: '06/14/2024', // Update the last time clients got exported
        active: false
    },
    profile: {
        tech_apt_permission: '2',
        tech_apt_permission_period: 'months'
    },
    default_location: 'gg1', // TODO make this dynamic based on locations storage in db
    locations: {
        gg1: {
            name: 'Edgewater',
            prefix: 'edgwr',
            address: '190 River Rd. Edgewater, NJ 07020',
            phone: '(201) 252-7264',
            page: 'https://drglowgetter.com/locations/edgewater-nj/'
        },
        gg2: {
            name: 'West New York',
            prefix: 'wny',
            address: '6035 Park Ave, West New York, NJ 07047',
            phone: '(201) 252-7264',
            page: 'https://drglowgetter.com/locations/west-new-york-nj/'
        }
    },
    texts: {
        sms_reminder: 'Are you sure you want to send an sms reminder?',
        email_reminder: 'Are you sure you want to send an email reminder?',
        sms_reminder_text: ' ✨\n' +
            'This is Dr. Glow Getter Aesthetic Boutique please finish booking your appointment to secure your spot or let us know if you have any questions 🤗\n' +
            'https://dr-gg.co/book', //add 'Hi {name}' at the beginning when using
    }
};

export default env;